import React from 'react';
import Layout from '../../components/Layout';

const PastMembersPage = () => (
	<Layout title='Past Board Members'>
		<h2 className='display-5 raleway-700 my-3 text-center'>Past Board Members</h2>
		<div className='row justify-content-evenly'>
			<div className='col-md-5 my-3'>
				<div className='display-7 raleway-700'>2021 - 22 Board</div>
				<ul>
					<li>Álvaro Romero-Calvo (President)</li>
					<li>Marshall Ledford (President-Elect)</li>
					<li>Annaliza Perez-Torres (Education & Outreach Coordinator)</li>
					<li>Bianca Serda (DEI Chair)</li>
					<li>Melika Osareh (Social Media & Website Coordinator)</li>
					<li>Yasmeen Sallam (Merchandise Coordinator)</li>
					<li>Taylor Watson (Membership Coordinator)</li>
					<li>Chad Vanden Bosh (Event Coordinator)</li>
					<li>Ruth Singh (Social Media Team)</li>
					<li>Mesgana Admassu (Social Media Team)</li>
				</ul>
			</div>
			<div className='col-md-5 my-3'>
				<h4 className='display-7 raleway-700'>2020 - 21 Board</h4>
				<ul>
					<li>Elizabeth Talburt (President)</li>
					<li>Álvaro Romero-Calvo (President-Elect)</li>
					<li>Marshall Ledford (Education & Outreach Coordinator)</li>
					<li>Taylor Walton (Social Media and Web Coordinator)</li>
					<li>Tristan Caro (DC Trip Coordinator)</li>
					<li>Jordan McKaig (SECA Coordinator)</li>
					<li>Bianca Serda (DEI Coordinator)</li>
				</ul>
			</div>

			<div className='col-md-5 my-3'>
				<h4 className='display-7 raleway-700'>2019 - 20 Board</h4>
				<ul>
					<li>Jordan McKaig (President)</li>
					<li>Elizabeth Talburt (President-Elect)</li>
					<li>Andy Pelos (Education & Outreach Coordinator)</li>
					<li>Mikayla Buckley (Education & Outreach Coordinator)</li>
					<li>Bianca Serda (Merchandise Coordinator)</li>
					<li>Taylor Walton (Social Media and Web Coordinator)</li>
					<li>Tristan Caro (DC Trip Coordinator)</li>
				</ul>
			</div>

			<div className='col-md-5 my-3'>
				<h4 className='display-7 raleway-700'>2018 - 19 Board</h4>
				<ul>
					<li>Esther Putman (President)</li>
					<li>Jordan McKaig (President-Elect)</li>
					<li>Elizabeth Talburt (Merchandise Coordinator)</li>
					<li>Mallika Sarma (Social Media and Web Coordinator)</li>
					<li>Andy Pelos (DC Trip Coordinator)</li>
				</ul>
			</div>

			<div className='col-md-5 my-3'>
				<h4 className='display-7 raleway-700'>2017 - 18 Board</h4>
				<ul>
					<li>Samantha McBride (President)</li>
					<li>Ramya Bhaskar (President-Elect)</li>
					<li>Claudia-F. Lopez Camara (Social Media and Web Coordinator)</li>
					<li>Andy Pelos (DC Trip Coordinator)</li>
				</ul>
			</div>
		</div>
	</Layout>
);

export default PastMembersPage;
